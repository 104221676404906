import { FC } from "react";
import { Typography } from "@mui/material";

interface Props {
  title: string;
  _marginBottom?: string;
  _color?: string;
}

const H5BoldHeader: FC<Props> = ({
  title = "",
  _marginBottom = "24px",
  _color = "#171725",
}) => {
  return (
    <Typography
      style={{
        fontFamily: "Montserrat",
        fontWeight: "700",
        fontSize: "32px",
        lineHeight: "40px",
        color: _color,
        marginBottom: _marginBottom,
      }}
    >
      {title}
    </Typography>
  );
};

export default H5BoldHeader;