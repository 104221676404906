import { FC, useEffect, useState } from 'react'
import { NameLogoBlue, CirculerPatern, MedalSuccess } from '../assets'
import { Divider, Grid, Typography } from '@mui/material'
import { CommonLoading, CommonLightButton } from '../components'
import { OpenAPI } from "../client";
import { useNavigate } from "react-router";
import { MainSystemLoginUrl, StorageAccessToken } from '../utils/constants';

const PremiumCodeSuccess:FC = () => {
    const navigate = useNavigate()
    const access_token = localStorage.getItem(StorageAccessToken);

    useEffect(() => {
      if (OpenAPI.TOKEN == undefined && access_token != null) {
        OpenAPI.TOKEN = access_token;
      }
    }, []);

    const [timeLeft, setTimeLeft] = useState<number>(5);

    useEffect(() => {
      if(timeLeft===0){
          window.location.href = `${MainSystemLoginUrl}/${access_token}`
      }
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
    
        return () => clearInterval(intervalId);
      }, [timeLeft]);

  return (
    <>
    <img
      src={NameLogoBlue}
      alt=""
      style={{ marginTop: "20px", marginBottom: "20px", cursor:"pointer" }}
    />
    <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
    <CommonLoading loading={false} />
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "70px",
        }}
      >
        <img src={CirculerPatern} alt="" style={{ position: "relative" }} />
        <img
          src={MedalSuccess}
          alt=""
          style={{ position: "absolute" }}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "32px",
            color: "#171725",
            marginTop: "32px",
          }}
        >
          Premium Kod Kullanımı Başarılı!
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#9CA4AB",
            marginTop: "24px",
            marginBottom: "32px",
          }}
        >
          ParentWiser ailesine hoş geldiniz!
        </Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <CommonLightButton
          text="ParentWiser’a Yönlendiriliyorsunuz"
          customCSS={{ width: "fit-content" }}
          fontSize="16px"
          onClick={() => window.location.href = `${MainSystemLoginUrl}/${access_token}`}
        />
      </Grid>
    </Grid>
  </>
  )
}

export default PremiumCodeSuccess