/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddUserFeedbackDto } from '../models/AddUserFeedbackDto';
import type { ArticleModel } from '../models/ArticleModel';
import type { ArticleType } from '../models/ArticleType';
import type { AskWiserPrepDataDto } from '../models/AskWiserPrepDataDto';
import type { AskWiserQuestionDto } from '../models/AskWiserQuestionDto';
import type { AuthToken } from '../models/AuthToken';
import type { BaseUserEmailPhoneModel } from '../models/BaseUserEmailPhoneModel';
import type { Body_change_profile_picture_user_change_profile_picture_post } from '../models/Body_change_profile_picture_user_change_profile_picture_post';
import type { Body_delete_user_favorite_without_path_parameter_user_user_favorite_without_path_parameter_delete } from '../models/Body_delete_user_favorite_without_path_parameter_user_user_favorite_without_path_parameter_delete';
import type { Body_reset_password_user_reset_password_post } from '../models/Body_reset_password_user_reset_password_post';
import type { BulletinModel } from '../models/BulletinModel';
import type { ChildCreateModel } from '../models/ChildCreateModel';
import type { ChildPatchModel } from '../models/ChildPatchModel';
import type { ChildUpsertModel } from '../models/ChildUpsertModel';
import type { CompleteSurvey } from '../models/CompleteSurvey';
import type { CreateProgressLog } from '../models/CreateProgressLog';
import type { CreateUserFavoriteModel } from '../models/CreateUserFavoriteModel';
import type { CreateUserWithoutEmailOrPhoneResponse } from '../models/CreateUserWithoutEmailOrPhoneResponse';
import type { EncryptedUserIdForCertificate } from '../models/EncryptedUserIdForCertificate';
import type { ExplainedTextDto } from '../models/ExplainedTextDto';
import type { GameDataItem } from '../models/GameDataItem';
import type { GetArticlesResponse } from '../models/GetArticlesResponse';
import type { GetDailyQuotesDto } from '../models/GetDailyQuotesDto';
import type { GetParentSchoolModuleResponse } from '../models/GetParentSchoolModuleResponse';
import type { GetPrivateUrlofArticleVideoResponse } from '../models/GetPrivateUrlofArticleVideoResponse';
import type { GetTrainingSetsResponse } from '../models/GetTrainingSetsResponse';
import type { GetUserPartnerCodeResponse } from '../models/GetUserPartnerCodeResponse';
import type { GetWhatBringsUserSuggestionItem } from '../models/GetWhatBringsUserSuggestionItem';
import type { HomeDesignerBucketDocUser } from '../models/HomeDesignerBucketDocUser';
import type { IsRequestSuccessfulModel } from '../models/IsRequestSuccessfulModel';
import type { LearnWithQuestionItemModel } from '../models/LearnWithQuestionItemModel';
import type { ListOfSurveys } from '../models/ListOfSurveys';
import type { ListOfUserFavorites } from '../models/ListOfUserFavorites';
import type { ListOfUserNotifications } from '../models/ListOfUserNotifications';
import type { ParentSchoolCertificateDetails } from '../models/ParentSchoolCertificateDetails';
import type { ParentSchoolModuleModel } from '../models/ParentSchoolModuleModel';
import type { PartnerDetail } from '../models/PartnerDetail';
import type { ProgressLogItem } from '../models/ProgressLogItem';
import type { ProgressStatusItem } from '../models/ProgressStatusItem';
import type { routers__user_router__ListOfChildren } from '../models/routers__user_router__ListOfChildren';
import type { SetLearnWithQuestionAnswer } from '../models/SetLearnWithQuestionAnswer';
import type { SurveyComparisonItem } from '../models/SurveyComparisonItem';
import type { SurveyDetail } from '../models/SurveyDetail';
import type { SurveyLogsResponse } from '../models/SurveyLogsResponse';
import type { SurveyLogsWithNoPaginationResponse } from '../models/SurveyLogsWithNoPaginationResponse';
import type { SurveyResult } from '../models/SurveyResult';
import type { TemporaryUserToken } from '../models/TemporaryUserToken';
import type { TrainingSetModel } from '../models/TrainingSetModel';
import type { UserCreateModel } from '../models/UserCreateModel';
import type { UserFamilyCodeModel } from '../models/UserFamilyCodeModel';
import type { UserPatchModel } from '../models/UserPatchModel';
import type { UserProfileModel } from '../models/UserProfileModel';
import type { UserShareLinkResponse } from '../models/UserShareLinkResponse';
import type { UserSubscriptionDto } from '../models/UserSubscriptionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Create User
     * @param requestBody
     * @returns AuthToken Successful Response
     * @throws ApiError
     */
    public static createUserUserUserPost(
        requestBody: UserCreateModel,
    ): CancelablePromise<AuthToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch User
     * Update fields of User
     * @param requestBody
     * @returns UserPatchModel Successful Response
     * @throws ApiError
     */
    public static patchUserUserUserPatch(
        requestBody: UserPatchModel,
    ): CancelablePromise<UserPatchModel> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create User Without Email And Phone
     * this endpoint is used for creating temporary user. For users to use app without bothering with registration form
     * @returns CreateUserWithoutEmailOrPhoneResponse Successful Response
     * @throws ApiError
     */
    public static createUserWithoutEmailAndPhoneUserCreateUserWithoutEmailAndPhonePost(): CancelablePromise<CreateUserWithoutEmailOrPhoneResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/create-user-without-email-and-phone',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Login Temporary Logged In User
     * @param requestBody
     * @returns TemporaryUserToken Successful Response
     * @throws ApiError
     */
    public static loginTemporaryLoggedInUserUserLoginTemporaryLoggedInUserPost(
        requestBody: UserCreateModel,
    ): CancelablePromise<TemporaryUserToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/login-temporary-logged-in-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Profile Details
     * @returns UserProfileModel Successful Response
     * @throws ApiError
     */
    public static getProfileDetailsUserGetProfileDetailsGet(): CancelablePromise<UserProfileModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-profile-details',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Update Password
     * This endpoint is used when user is logged in and wants to change his/her password
     * @param newPassword
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static updatePasswordUserUpdatePasswordPost(
        newPassword: any,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/update-password',
            query: {
                'new_password': newPassword,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Password Recovery
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static passwordRecoveryUserPasswordRecoveryPost(
        requestBody: BaseUserEmailPhoneModel,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/password-recovery',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Password
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static resetPasswordUserResetPasswordPost(
        requestBody: Body_reset_password_user_reset_password_post,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/reset-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deactivate User
     * @param accountPasswordForConfirmation
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static deactivateUserUserDeactivateUserDelete(
        accountPasswordForConfirmation: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/deactivate-user',
            query: {
                'account_password_for_confirmation': accountPasswordForConfirmation,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deactivate User By Recaptcha Token
     * @param recaptchaToken
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static deactivateUserByRecaptchaTokenUserDeactivateUserByRecaptchaTokenDelete(
        recaptchaToken: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/deactivate-user-by-recaptcha-token',
            query: {
                'recaptcha_token': recaptchaToken,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get List Of Children
     * @returns routers__user_router__ListOfChildren Successful Response
     * @throws ApiError
     */
    public static getListOfChildrenUserChildrenGet(): CancelablePromise<routers__user_router__ListOfChildren> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/children',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Create New Child
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static createNewChildUserChildPost(
        requestBody: ChildCreateModel,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/child',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create New Child With List
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static createNewChildWithListUserChildAddWithListPost(
        requestBody: Array<ChildCreateModel>,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/child_add_with_list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upsert Children
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static upsertChildrenUserUpsertChildWithListPut(
        requestBody: Array<ChildUpsertModel>,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user/upsert-child-with-list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Child
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static patchChildUserChildChildIdPatch(
        requestBody: ChildPatchModel,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user/child/{child_id}',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Child From User
     * @param childId
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static removeChildFromUserUserRemoveChildFromUserChildIdDelete(
        childId: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/remove-child-from-user/{child_id}',
            path: {
                'child_id': childId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Partner Code
     * @returns GetUserPartnerCodeResponse Successful Response
     * @throws ApiError
     */
    public static getPartnerCodeUserGetPartnerCodeGet(): CancelablePromise<GetUserPartnerCodeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-partner-code',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Add Partner To My Account
     * @param partnerCode
     * @returns PartnerDetail Successful Response
     * @throws ApiError
     */
    public static addPartnerToMyAccountUserAddPartnerToMyAccountPost(
        partnerCode: string,
    ): CancelablePromise<PartnerDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/add-partner-to-my-account',
            query: {
                'partner_code': partnerCode,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Show My Partners
     * @returns PartnerDetail Successful Response
     * @throws ApiError
     */
    public static showMyPartnersUserShowMyPartnersGet(): CancelablePromise<Array<PartnerDetail>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/show-my-partners',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Delete Partner From My Account
     * @param partnerId
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static deletePartnerFromMyAccountUserDeletePartnerFromMyAccountDelete(
        partnerId: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/delete-partner-from-my-account',
            query: {
                'partner_id': partnerId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Family Code Of User
     * If a user wants another user to see their child and add to his/her account, a code should be shared.
     * @returns UserFamilyCodeModel Successful Response
     * @throws ApiError
     */
    public static getFamilyCodeOfUserUserUserFamilyCodeGet(): CancelablePromise<UserFamilyCodeModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/user-family-code',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Children Of User Family Code
     * After a user enters family code, all children of code owner are shown.
     * @param familyOtp
     * @returns routers__user_router__ListOfChildren Successful Response
     * @throws ApiError
     */
    public static getChildrenOfUserFamilyCodeUserGetChildrenOfUserFamilyCodeGet(
        familyOtp: string,
    ): CancelablePromise<routers__user_router__ListOfChildren> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-children-of-user-family-code',
            query: {
                'family_otp': familyOtp,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Register As Parent To Child
     * @param childId
     * @param familyOtp
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static registerAsParentToChildUserRegisterAsParentToChildPost(
        childId: string,
        familyOtp: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/register-as-parent-to-child',
            query: {
                'child_id': childId,
                'family_otp': familyOtp,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Register As Parent To List Of Children
     * @param familyOtp
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static registerAsParentToListOfChildrenUserRegisterAsParentToListOfChildrenPost(
        familyOtp: string,
        requestBody: Array<string>,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/register-as-parent-to-list-of-children',
            query: {
                'family_otp': familyOtp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add User Favorite
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static addUserFavoriteUserAddUserFavoritePost(
        requestBody: CreateUserFavoriteModel,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/add-user-favorite',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Favorites
     * @returns ListOfUserFavorites Successful Response
     * @throws ApiError
     */
    public static getUserFavoritesUserUserFavoritesGet(): CancelablePromise<ListOfUserFavorites> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/user-favorites',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Delete User Favorite
     * @param userFavoriteId
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static deleteUserFavoriteUserUserFavoriteUserFavoriteIdDelete(
        userFavoriteId: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/user-favorite/{user_favorite_id}',
            path: {
                'user_favorite_id': userFavoriteId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User Favorite Without Path Parameter
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static deleteUserFavoriteWithoutPathParameterUserUserFavoriteWithoutPathParameterDelete(
        requestBody: Body_delete_user_favorite_without_path_parameter_user_user_favorite_without_path_parameter_delete,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/user-favorite-without-path-parameter',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Notifications
     * @param startAfterNotificationId For pagination send notification_id of last notification.
     * @returns ListOfUserNotifications Successful Response
     * @throws ApiError
     */
    public static getNotificationsUserGetNotificationsGet(
        startAfterNotificationId?: string,
    ): CancelablePromise<ListOfUserNotifications> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-notifications',
            query: {
                'start_after_notification_id': startAfterNotificationId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User Notification
     * @param notificationId
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static deleteUserNotificationUserUserNotificationNotificationIdDelete(
        notificationId: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/user-notification/{notification_id}',
            path: {
                'notification_id': notificationId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Article
     * @param articleId
     * @returns ArticleModel Successful Response
     * @throws ApiError
     */
    public static getArticleUserArticleArticleIdGet(
        articleId: string,
    ): CancelablePromise<ArticleModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/article/{article_id}',
            path: {
                'article_id': articleId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Private Url Of Article Video
     * @param articleId
     * @param recaptchaToken
     * @returns GetPrivateUrlofArticleVideoResponse Successful Response
     * @throws ApiError
     */
    public static getPrivateUrlOfArticleVideoUserGetVideoOfArticleArticleIdGet(
        articleId: string,
        recaptchaToken: string,
    ): CancelablePromise<GetPrivateUrlofArticleVideoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-video-of-article/{article_id}',
            path: {
                'article_id': articleId,
            },
            query: {
                'recaptcha_token': recaptchaToken,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Articles
     * @param startAfterArticleId
     * @returns GetArticlesResponse Successful Response
     * @throws ApiError
     */
    public static getArticlesUserArticlesGet(
        startAfterArticleId?: string,
    ): CancelablePromise<GetArticlesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/articles',
            query: {
                'start_after_article_id': startAfterArticleId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Articles Filtered
     * @param startAfterArticleId
     * @param articleType
     * @param customLimit
     * @returns GetArticlesResponse Successful Response
     * @throws ApiError
     */
    public static getArticlesFilteredUserArticlesFilteredGet(
        startAfterArticleId?: string,
        articleType?: ArticleType,
        customLimit: number = 10,
    ): CancelablePromise<GetArticlesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/articles-filtered',
            query: {
                'start_after_article_id': startAfterArticleId,
                'article_type': articleType,
                'custom_limit': customLimit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Training Sets
     * @param startAfterTrainingSetId
     * @returns GetTrainingSetsResponse Successful Response
     * @throws ApiError
     */
    public static getTrainingSetsUserTrainingSetsGet(
        startAfterTrainingSetId?: string,
    ): CancelablePromise<GetTrainingSetsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/training-sets',
            query: {
                'start_after_training_set_id': startAfterTrainingSetId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Training Set
     * @param trainingSetId
     * @returns TrainingSetModel Successful Response
     * @throws ApiError
     */
    public static getTrainingSetUserTrainingSetTrainingSetIdGet(
        trainingSetId: string,
    ): CancelablePromise<TrainingSetModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/training-set/{training_set_id}',
            path: {
                'training_set_id': trainingSetId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Parent School
     * @param startAfterModuleId
     * @returns GetParentSchoolModuleResponse Successful Response
     * @throws ApiError
     */
    public static getParentSchoolUserParentSchoolGet(
        startAfterModuleId?: string,
    ): CancelablePromise<GetParentSchoolModuleResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/parent-school',
            query: {
                'start_after_module_id': startAfterModuleId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Parent School Module
     * @param parentSchoolModuleId
     * @returns ParentSchoolModuleModel Successful Response
     * @throws ApiError
     */
    public static getParentSchoolModuleUserParentSchoolModuleParentSchoolModuleIdGet(
        parentSchoolModuleId: string,
    ): CancelablePromise<ParentSchoolModuleModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/parent-school-module/{parent_school_module_id}',
            path: {
                'parent_school_module_id': parentSchoolModuleId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Profile Picture
     * @param formData
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static changeProfilePictureUserChangeProfilePicturePost(
        formData: Body_change_profile_picture_user_change_profile_picture_post,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/change-profile-picture',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Profile Picture
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static deleteProfilePictureUserProfilePictureDelete(): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/profile-picture',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Surveys
     * @returns ListOfSurveys Successful Response
     * @throws ApiError
     */
    public static getSurveysUserSurveysGet(): CancelablePromise<ListOfSurveys> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/surveys',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Survey
     * @param surveyToken
     * @returns SurveyDetail Successful Response
     * @throws ApiError
     */
    public static getSurveyUserSurveySurveyTokenGet(
        surveyToken: string,
    ): CancelablePromise<SurveyDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/survey/{survey_token}',
            path: {
                'survey_token': surveyToken,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Complete Survey
     * @param requestBody
     * @returns SurveyResult Successful Response
     * @throws ApiError
     */
    public static completeSurveyUserCompleteSurveyPost(
        requestBody: CompleteSurvey,
    ): CancelablePromise<SurveyResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/complete-survey',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Survey Logs
     * @param lastSurveyLogId
     * @returns SurveyLogsResponse Successful Response
     * @throws ApiError
     */
    public static getSurveyLogsUserSurveyLogsGet(
        lastSurveyLogId?: string,
    ): CancelablePromise<SurveyLogsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/survey-logs',
            query: {
                'last_survey_log_id': lastSurveyLogId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Survey Logs With No Pagination
     * @returns SurveyLogsWithNoPaginationResponse Successful Response
     * @throws ApiError
     */
    public static getSurveyLogsWithNoPaginationUserSurveyLogsWithNoPaginationGet(): CancelablePromise<SurveyLogsWithNoPaginationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/survey-logs-with-no-pagination',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Compare Survey Results Of Other Parents
     * A user can use this endpoint for comparison of other parents' survey results on a child
     * @param childId
     * @param surveyId
     * @returns SurveyComparisonItem Successful Response
     * @throws ApiError
     */
    public static compareSurveyResultsOfOtherParentsUserCompareSurveyResultsChildIdSurveyIdGet(
        childId: string,
        surveyId: number,
    ): CancelablePromise<Array<SurveyComparisonItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/compare-survey-results/{child_id}/{survey_id}',
            path: {
                'child_id': childId,
                'survey_id': surveyId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Activate Premium With Premium Code
     * @param premiumCode
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static activatePremiumWithPremiumCodeUserActivatePremiumWithCodePost(
        premiumCode: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/activate-premium-with-code',
            query: {
                'premium_code': premiumCode,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Push Notification Token
     * @param pushNotificationToken
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static savePushNotificationTokenUserPushNotificationTokenPost(
        pushNotificationToken: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/push-notification-token',
            query: {
                'push_notification_token': pushNotificationToken,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Push Notification Token
     * @param pushNotificationToken
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static deletePushNotificationTokenUserPushNotificationTokenDelete(
        pushNotificationToken: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/push-notification-token',
            query: {
                'push_notification_token': pushNotificationToken,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Progress Log
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static addProgressLogUserProgressLogPost(
        requestBody: CreateProgressLog,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/progress-log',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Progress Logs
     * @returns ProgressLogItem Successful Response
     * @throws ApiError
     */
    public static getProgressLogsUserProgressLogsGet(): CancelablePromise<Array<ProgressLogItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/progress-logs',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Progress Statuses
     * @returns ProgressStatusItem Successful Response
     * @throws ApiError
     */
    public static getProgressStatusesUserProgressStatusesGet(): CancelablePromise<Array<ProgressStatusItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/progress-statuses',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Homepage Contents
     * @returns HomeDesignerBucketDocUser Successful Response
     * @throws ApiError
     */
    public static getHomepageContentsUserGetHomepageContentsGet(): CancelablePromise<Array<HomeDesignerBucketDocUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-homepage-contents',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Game Data
     * @returns GameDataItem Successful Response
     * @throws ApiError
     */
    public static getGameDataUserGetGameDataGet(): CancelablePromise<Array<GameDataItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-game-data',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Daily Quotes By User
     * @returns GetDailyQuotesDto Successful Response
     * @throws ApiError
     */
    public static getDailyQuotesByUserUserDailyQuotesGet(): CancelablePromise<Array<GetDailyQuotesDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/daily-quotes',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Todays Quote
     * @returns GetDailyQuotesDto Successful Response
     * @throws ApiError
     */
    public static getTodaysQuoteUserTodaysQuoteGet(): CancelablePromise<GetDailyQuotesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/todays-quote',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Shareable Link Of User
     * @returns UserShareLinkResponse Successful Response
     * @throws ApiError
     */
    public static getShareableLinkOfUserUserGetSharableLinkOfUserGet(): CancelablePromise<UserShareLinkResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-sharable-link-of-user',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Add User Feedback
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static addUserFeedbackUserAddUserFeedbackPost(
        requestBody: AddUserFeedbackDto,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/add-user-feedback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get What Brings User Suggestions
     * @returns GetWhatBringsUserSuggestionItem Successful Response
     * @throws ApiError
     */
    public static getWhatBringsUserSuggestionsUserGetWhatBringsUserSuggestionsGet(): CancelablePromise<Array<GetWhatBringsUserSuggestionItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-what-brings-user-suggestions',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Set What Brings User
     * @param selectedId
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static setWhatBringsUserUserSetWhatBringsUserSelectedIdPost(
        selectedId: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/set-what-brings-user/{selected_id}',
            path: {
                'selected_id': selectedId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Area Of Interests
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getAreaOfInterestsUserGetAreaOfInterestsGet(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-area-of-interests',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Set User Area Of Interests
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static setUserAreaOfInterestsUserSetUserAreaOfInterestsPut(
        requestBody?: Array<string>,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user/set-user-area-of-interests',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Bulletion Content
     * @param paginationItemCount
     * @param lastDocumentId
     * @returns BulletinModel Successful Response
     * @throws ApiError
     */
    public static getBulletionContentUserGetBulletinContentGet(
        paginationItemCount: number = 1,
        lastDocumentId?: string,
    ): CancelablePromise<Array<BulletinModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-bulletin-content',
            query: {
                'pagination_item_count': paginationItemCount,
                'last_document_id': lastDocumentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Bulletin Content
     * @param bulletinId
     * @returns BulletinModel Successful Response
     * @throws ApiError
     */
    public static getBulletinContentUserGetBulletinBulletinIdGet(
        bulletinId: string,
    ): CancelablePromise<BulletinModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-bulletin/{bulletin_id}',
            path: {
                'bulletin_id': bulletinId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Country City Data
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCountryCityDataUserGetCountryCityDataGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-country-city-data',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Popular Searches
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getPopularSearchesUserGetPopularSearchesGet(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-popular-searches',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get All Explained Texts
     * @returns ExplainedTextDto Successful Response
     * @throws ApiError
     */
    public static getAllExplainedTextsUserGetAllExplainedTextsGet(): CancelablePromise<Array<ExplainedTextDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-all-explained-texts',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Explained Text With Slug
     * @param slug
     * @returns ExplainedTextDto Successful Response
     * @throws ApiError
     */
    public static getExplainedTextWithSlugUserGetExplainedTextWithSlugSlugGet(
        slug: string,
    ): CancelablePromise<ExplainedTextDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-explained-text-with-slug/{slug}',
            path: {
                'slug': slug,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Phone Verification Before Register Sms
     * @param phone
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static sendPhoneVerificationBeforeRegisterSmsUserSendPhoneVerificationBeforeRegisterSmsPost(
        phone: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/send_phone_verification_before_register_sms',
            query: {
                'phone': phone,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Verify Phone Before Register
     * @param phone
     * @param verificationCode
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static verifyPhoneBeforeRegisterUserVerifyPhoneBeforeRegisterPost(
        phone: string,
        verificationCode: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/verify_phone_before_register',
            query: {
                'phone': phone,
                'verification_code': verificationCode,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Email Verification Before Register
     * @param email
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static sendEmailVerificationBeforeRegisterUserSendEmailVerificationBeforeRegisterPost(
        email: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/send_email_verification_before_register',
            query: {
                'email': email,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Verify Email Before Register
     * @param email
     * @param verificationCode
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static verifyEmailBeforeRegisterUserVerifyEmailBeforeRegisterPost(
        email: string,
        verificationCode: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/verify_email_before_register',
            query: {
                'email': email,
                'verification_code': verificationCode,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Learn With Questions
     * @param paginationItemCount
     * @returns LearnWithQuestionItemModel Successful Response
     * @throws ApiError
     */
    public static getLearnWithQuestionsUserGetLearnWithQuestionsGet(
        paginationItemCount: number = 10,
    ): CancelablePromise<Array<LearnWithQuestionItemModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-learn-with-questions',
            query: {
                'pagination_item_count': paginationItemCount,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Learn With Question
     * @param orderOfLearnWithQuestionId
     * @returns LearnWithQuestionItemModel Successful Response
     * @throws ApiError
     */
    public static getLearnWithQuestionUserGetLearnWithQuestionOrderOfLearnWithQuestionIdGet(
        orderOfLearnWithQuestionId: number,
    ): CancelablePromise<LearnWithQuestionItemModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-learn-with-question/{order_of_learn_with_question_id}',
            path: {
                'order_of_learn_with_question_id': orderOfLearnWithQuestionId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Learn With Questions Answer
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static setLearnWithQuestionsAnswerUserSetLearnWithQuestionsAnswerPost(
        requestBody: SetLearnWithQuestionAnswer,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/set-learn-with-questions-answer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Ask Wiser Prepare Data Set
     * @param requestBody
     * @returns AskWiserPrepDataDto Successful Response
     * @throws ApiError
     */
    public static askWiserPrepareDataSetUserAskWiserPrepareDataSetPost(
        requestBody: AskWiserQuestionDto,
    ): CancelablePromise<AskWiserPrepDataDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/ask-wiser-prepare-data-set',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Ask Wiser Question Long Polling
     * @param score
     * @param question
     * @param contentId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static askWiserQuestionLongPollingUserAskWiserQuestionLongPollingGet(
        score: number,
        question: string,
        contentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/ask-wiser-question-long-polling',
            query: {
                'score': score,
                'question': question,
                'content_id': contentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Subscription Detail
     * @returns UserSubscriptionDto Successful Response
     * @throws ApiError
     */
    public static getUserSubscriptionDetailUserGetUserSubscriptionDetailGet(): CancelablePromise<UserSubscriptionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-user-subscription-detail',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Encrypted User Id For Certificate
     * @returns EncryptedUserIdForCertificate Successful Response
     * @throws ApiError
     */
    public static getEncryptedUserIdForCertificateUserGetEncryptedUserIdForCertificateGet(): CancelablePromise<EncryptedUserIdForCertificate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-encrypted-user-id-for-certificate',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Parent School Certificate Details
     * @param encryptedUserIdForCertificate
     * @returns ParentSchoolCertificateDetails Successful Response
     * @throws ApiError
     */
    public static getParentSchoolCertificateDetailsUserGetParentSchoolCertificateDetailsEncryptedUserIdForCertificateGet(
        encryptedUserIdForCertificate: string,
    ): CancelablePromise<ParentSchoolCertificateDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/get-parent-school-certificate-details/{encrypted_user_id_for_certificate}',
            path: {
                'encrypted_user_id_for_certificate': encryptedUserIdForCertificate,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
