/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthToken } from '../models/AuthToken';
import type { Body_login_for_access_token_token_post } from '../models/Body_login_for_access_token_token_post';
import type { OauthProvider } from '../models/OauthProvider';
import type { TemporaryUserToken } from '../models/TemporaryUserToken';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Root
     * @returns any Successful Response
     * @throws ApiError
     */
    public static rootGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
        });
    }

    /**
     * Login For Access Token
     * Username can be either phone of customer(90555443322) or email
     * @param formData
     * @returns AuthToken Successful Response
     * @throws ApiError
     */
    public static loginForAccessTokenTokenPost(
        formData: Body_login_for_access_token_token_post,
    ): CancelablePromise<AuthToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Token From Oauth Provider
     * This endpoint is used when user wants to login with oauth provider
     * @param oauthProvider
     * @param providerToken
     * @returns AuthToken Successful Response
     * @throws ApiError
     */
    public static getTokenFromOauthProviderGetTokenFromOauthProviderPost(
        oauthProvider: OauthProvider,
        providerToken: string,
    ): CancelablePromise<AuthToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/get-token-from-oauth-provider',
            query: {
                'oauth_provider': oauthProvider,
                'provider_token': providerToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Token Of Temporary User
     * This endpoint is used when an app is created with /user/create-user-without-email-and-phone endpoint and didn't completed registation form
     * @param userId
     * @returns TemporaryUserToken Successful Response
     * @throws ApiError
     */
    public static getTokenOfTemporaryUserGetTokenOfTemporaryUserPost(
        userId: string,
    ): CancelablePromise<TemporaryUserToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/get-token-of-temporary-user',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refresh User Token
     * This endpoint is used when user's session done but still needs to stay in app. This endpoint will refresh user token.
     * @param refreshToken
     * @returns AuthToken Successful Response
     * @throws ApiError
     */
    public static refreshUserTokenRefreshUserTokenPost(
        refreshToken: string,
    ): CancelablePromise<AuthToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/refresh-user-token',
            query: {
                'refresh_token': refreshToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Endpoints
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listEndpointsListEndpointsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/list-endpoints',
        });
    }

}
