import { FC, useEffect,useState } from "react";
import {
  CommonButton,
  CommonLabel,
  CommonLoading,
  CommonTextField,
  H5BoldHeader,
  CommonErrorModal
} from "../components";
import { NameLogoBlue } from "../assets";
import { Container, Grid, Typography, Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { UserService } from "../client";

const ResetCreateNewPassword: FC = () => {
  const {type, otp} = useParams()
  const navigate = useNavigate()

  const [password, setPassword] = useState<string>("")
  const [passwordRepeat, setPasswordRepeat] = useState<string>("")
  const [recoveryCode, setRecoveryCode] = useState<string>("")

  const [localLoading, setLocalLoading] = useState<boolean>(false)
  const [errorModalOpen, setErrorModalOpen] = useState<string | null>(null);

  useEffect(() => {
    if(type == undefined || type == null || otp == undefined || otp == null){
      navigate('/reset-password')
    }
  }, [type, otp])

  const submitChangePasswordRequest = async() => {
    try {
      setErrorModalOpen(null)
      setLocalLoading(true) 

      if(password != passwordRepeat){
        setErrorModalOpen("Şifre ve şifre tekrarı aynı olmalı.")
        setLocalLoading(false)
        return
      }

      const newPasswordResponse = await UserService.resetPasswordUserResetPasswordPost({
        base_user_email_phone_model:{
          email: type == "email" ? otp : undefined,
          phone: type == "phone" ? (otp ? parseInt(otp) : 0) : undefined
        },
        password_recovery_code: parseInt(recoveryCode),
        new_password: password
      })

      if(newPasswordResponse.is_request_successful){
        navigate('/login')
      }else{
        setErrorModalOpen("Doğrulama kodu hatalı")
      }
      setLocalLoading(false)
    } catch (error:any) {
      setErrorModalOpen("Beklenmedik bir hata ile karşılaştık lütfen bizimle iletişime geçin. +90 850 305 71 29")
      setLocalLoading(false)
    }
  }

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonLoading loading={localLoading} />
      <CommonErrorModal
        isOpen={errorModalOpen != null ? true : false}
        title="Hata"
        text={errorModalOpen == null ? "" : errorModalOpen}
        buttonText="Tekrar Dene"
        onButtonFunc={() => setErrorModalOpen(null)}
      />
      <Container maxWidth="lg">
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "90vh",
          }}
        >
          <Grid item xs={4} sm={8} md={12}>
            <H5BoldHeader title="Yeni Şifre Belirle" _marginBottom="15px" />
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#9CA4AB",
                marginBottom: "45px",
              }}
            >
              Yeni bir şifre belirleyin.
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{
              textAlign: "start",
              marginBottom: "40px",
              width: { xs: "95%", sm: "80%", md: "50%" },
            }}
          >
            <CommonLabel label="Doğrulama Kodu" />
            <CommonTextField _placeHolder="Telefon ya da eposta adresinize gelen doğrulama kodunu girin." _onChange={(e:string) => setRecoveryCode(e)}/>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{
              textAlign: "start",
              marginBottom: "40px",
              width: { xs: "95%", sm: "80%", md: "50%" },
            }}
          >
            <CommonLabel label="Şifre" />
            <CommonTextField _placeHolder="Yeni bir şifre belirleyin." _type="password" _onChange={(e:string) => setPassword(e)}/>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{
              textAlign: "start",
              width: { xs: "95%", sm: "80%", md: "50%" },
            }}
          >
            <CommonLabel label="Şifre Tekrar" />
            <CommonTextField _placeHolder="Şifreyi tekrar girin." _type="password" _onChange={(e:string) => setPasswordRepeat(e)}/>
            <CommonButton
              text="Şifremi Değiştir"
              customCSS={{ marginTop: "30px" }}
              onClick={submitChangePasswordRequest}
              disabled={
                recoveryCode.length < 5 || password.length < 5 || passwordRepeat.length < 5 ? true : false
              }
            />
          </Grid>
          <Grid item xs={4} sm={8} md={12} style={{marginTop:"15px"}}>
          <Typography style={{
              fontFamily:"Montserrat",
              fontWeight:"600",
              fontSize:"18px",
              lineHeight:"26px",
              color:"#9CA4AB",
              cursor:"pointer"
            }}
              onClick={() => navigate("/reset-password")}
            >
              Geri
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ResetCreateNewPassword;