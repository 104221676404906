import { FC } from "react";
import { TextField } from "@mui/material";
import CommonButton from "./CommonButton";

interface Props {
  formName?: string;
  _disabled?: boolean;
  _value?: string | number;
  _defaultValue?: string | number;
  _type?: string;
  _placeHolder?: string;
  _onChange?: (e: any) => void;
  _textAlign?: string;
  buttonText: string;
  buttonDisable: boolean;
  buttonCSS?: object;
  buttonOnClick: () => void;
  buttonTextColor: string;
}

const CommonInputWithEndAdorment: FC<Props> = ({
  formName,
  _disabled,
  _value,
  _defaultValue,
  _type,
  _placeHolder = "",
  _onChange,
  _textAlign = "start",
  buttonText = "",
  buttonDisable = false,
  buttonCSS = {},
  buttonOnClick,
  buttonTextColor,
}) => {
  return (
    <TextField
      id="outlined-basic"
      disabled={_disabled == null || undefined ? false : _disabled}
      required
      fullWidth
      type={_type == null || undefined ? "text" : _type}
      name={formName}
      value={_value}
      defaultValue={_defaultValue}
      placeholder={_placeHolder}
      onChange={(e) => _onChange && _onChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <CommonButton
            text={buttonText}
            disabled={buttonDisable}
            customCSS={buttonCSS}
            onClick={buttonOnClick}
            textColor={buttonTextColor}
          />
        ),
      }}
      sx={{
        "& fieldset": { border: "1px solid #E9EBED", borderRadius: "16px" },
        input: {
          color: "#9CA4AB",
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "22px",
          textAlign: _textAlign,
        },
        borderRadius: "16px",
        backgroundColor: "#E6E6E6",
      }}
    />
  );
};

export default CommonInputWithEndAdorment;
