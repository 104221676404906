import { ErrorRed, Kids, NameLogoBlue } from "../assets";
import { Container, Divider, Grid, Typography } from "@mui/material";
import { CommonInputWithEndAdorment, CommonLoading } from "../components";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, FC } from "react";
import { StorageAccessToken } from "../utils/constants";
import { UserService, PurchaseService, OpenAPI } from "../client";

const Code:FC = () => {
  const navigate = useNavigate();

  const access_token = localStorage.getItem(StorageAccessToken);

  const [coupon, setCoupon] = useState<string>("");
  const [error, setError] = useState<string | null>(null)
  const [localLoading, setLocalLoading] = useState<boolean>(false)

  useEffect(() => {
    if(access_token == null){
      navigate("/login")
    }else {
      OpenAPI.TOKEN = access_token
    }
    
  }, [access_token])

  const activateWithPremiumCode = async () => {
    try {
      setLocalLoading(true);
      setError(null);
      try {
        const activateResponse =
        await UserService.activatePremiumWithPremiumCodeUserActivatePremiumWithCodePost(
          coupon
        );

        if (activateResponse.is_request_successful) {
          setLocalLoading(false);
          navigate(`/success`)
          return
        }
      } catch (error) {
        setError("Geçersiz Kod.");

      }

      // const discountCouponCheck = await PurchaseService.checkCouponCodePurchaseCheckCouponCodePost(coupon)
      // if(discountCouponCheck.discount_percent == 100){
      //   navigate(`/success`)
      // }

      setLocalLoading(false);
    } catch (error: any) {
      setError("Geçersiz Kod.");
    }
    setLocalLoading(false);
  };

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px", cursor: "pointer" }}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonLoading loading={localLoading} />
      <Container
        maxWidth="md"
        style={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid
              item
              xs={4}
              sm={8}
              md={4}
              style={{
                backgroundImage: `url(${Kids})`,
                borderTopLeftRadius: "35px",
                borderBottomLeftRadius: "35px",
                backgroundRepeat: "no-repeat",
              }}
            ></Grid>
            <Grid
              item
              xs={4}
              sm={8}
              md={8}
              sx={{
                textAlign: "start",
                backgroundColor: "#ECF1F6",
                paddingX: "50px",
                paddingY: { xs: "80px", sm: "80px", md: "130px" },
                borderTopRightRadius: "35px",
                borderBottomRightRadius: "35px",
                height: "fit-content",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  fontSize: "40px",
                  lineHeight: "48px",
                  color: "#171725",
                  marginBottom: "20px",
                }}
              >
                Premium Kod
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#66707A",
                  marginBottom: "20px",
                }}
              >
                Kurumunuzdan aldığınız ParentWiser Premium kodunu aşağıdaki
                alana giriniz.
              </Typography>
              <CommonInputWithEndAdorment
                buttonText={"Onayla"}
                _onChange={(e: string) => setCoupon(e)}
                _value={coupon}
                _defaultValue={coupon}
                buttonDisable={false}
                buttonOnClick={() => activateWithPremiumCode()}
                buttonCSS={{
                  width: "fit-content",
                  padding: "14px 0px",
                  backgroundColor: coupon == "" ? "#BFC6CC" : "#2574FF",
                  minWidth: "120px",
                  marginTop: "0px",
                  marginBottom: "0px",
                  marginRight: "-14px",
                  borderTopRightRadius: "16px",
                  borderBottomRightRadius: "16px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
                buttonTextColor={coupon == "" ? "#FFFFFF" : "#FFFFFF"}
                _placeHolder="Premium Kodu Girin"
                _disabled={false}
              />
              {
                error != null ? (
                  <div style={{
                    marginTop:"14px",
                    display:"flex",
                    alignItems:"center"
                  }}>
                    <img src={ErrorRed} alt="" style={{width:"12px", height:"12px"}} />
                    <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    fontSize: "9px",
                    marginLeft:"5px",
                    lineHeight: "16px",
                    color: "#FA4169",
                    textDecoration:"underline"
                  }}
                >
                  {error}
                </Typography>
                  </div>
                ): null
              }
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default Code;
