import { createBrowserRouter } from "react-router-dom";
import { Login, Register, Code, ResetPassword, ResetCreateNewPassword } from "../pages";
import PremiumCodeSuccess from "../pages/PremiumCodeSuccess";

export const routerItems = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/success",
    element: <PremiumCodeSuccess />,
  },
  {
    path:"/code",
    element: <Code />
  },
  {
    path:"/",
    element: <Register />
  },
  {
    path:"/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/create-new-password/:type/:otp",
    element: <ResetCreateNewPassword />,
  }
]);
