import { FC, useEffect, useState } from "react";
import {
  CssBaseline,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Divider,
} from "@mui/material";
import { NameLogoBlue } from "../assets";
import {
  AuthOnboardingSlider,
  CommonButton,
  CommonLabel,
  CommonTextField,
  GoogleButton,
  CommonAlert,
  CommonLoading,
  AppleOAuthButton,
} from "../components";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { DefaultService, OauthProvider } from "../client";
import { IAppleOAuthCallbackCredentials } from "../utils/commonModels";
import { StorageAccessToken } from "../utils/constants";

const theme = createTheme();

const Login: FC = () => {
  let navigate = useNavigate();

  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const access_token = localStorage.getItem(StorageAccessToken);

  useEffect(() => {
    setError(null);
  }, []);


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLocalLoading(true)
    try {
      const data = new FormData(event.currentTarget);
      const loginResponse = await DefaultService.loginForAccessTokenTokenPost({
        username: data.get("username") as string,
        password: data.get("password") as string,
      });
      localStorage.setItem(StorageAccessToken, loginResponse.access_token)
      navigate("/code")
    } catch (error:any) {
      setError("Kullanıcı adı veya şifre yanlış.")
    }
    setLocalLoading(false)
  };

  const loginWithGoogleFunc = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const loginResponse = await DefaultService.getTokenFromOauthProviderGetTokenFromOauthProviderPost(
          OauthProvider.GOOGLE,
          tokenResponse.access_token,
        )
        localStorage.setItem(StorageAccessToken, loginResponse.access_token)
        navigate("/code")
        return;
      } catch (error) {
        setError("Kayıtlı kullanıcı bulunamadı.")
      }
    },
    onError: (error) => setError("Kayıtlı kullanıcı bulunamadı."),
  });

  const loginWithAppleFunc = async (
    appleCredential: IAppleOAuthCallbackCredentials
  ) => {
      try {
        const loginResponse = await DefaultService.getTokenFromOauthProviderGetTokenFromOauthProviderPost(
          OauthProvider.APPLE,
          appleCredential.id_token,
        )
        console.log("===>",loginResponse)
        localStorage.setItem(StorageAccessToken, loginResponse.access_token)
        navigate("/code")
        return;
      } catch (error) {
        setError("Kayıtlı kullanıcı bulunamadı.")
      }
  };

  return (
    <ThemeProvider theme={theme}>
      <CommonLoading loading={localLoading} />
      <CommonAlert
        _type={"error"}
        _open={error != null ? true : false}
        _onClose={() => setError(null)}
        _message={"Kullanıcı Adı Veya Şifre Hatalı"}
      />
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            backgroundColor: { md: "#2574FF" },
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <AuthOnboardingSlider />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingLeft: { md: 7, lg: 7 },
              paddingRight: { md: 7, lg: 7 },
            }}
          >
            <img src={NameLogoBlue} alt="" />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "40px",
                color: "#171725",
                marginTop: "6%",
              }}
            >
              Merhaba 👋
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "40px",
                color: "#171725",
                marginBottom: "5%",
              }}
            >
              Tekrar Hoş geldin!
            </Typography>

            <GoogleButton
              text="Google ile giriş yap"
              _onClick={() => loginWithGoogleFunc()}
              _marginBottom="24px"
            />
            <AppleOAuthButton
              text="Apple ile giriş yap"
              _onClick={loginWithAppleFunc}
            />
            <Divider
              style={{ width: "100%", marginTop: "5%", marginBottom: "4%" }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                  color: "#78828A",
                }}
              >
                veya giriş yap
              </Typography>
            </Divider>

            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <div style={{ textAlign: "start", width: "100%" }}>
                <CommonLabel label="Telefon veya E-posta" />
                <CommonTextField
                  _placeHolder="Telefon veya e-postanı adresini gir"
                  formName="username"
                />
              </div>
              <div
                style={{ textAlign: "start", width: "100%", marginTop: "3%", marginBottom:"6%" }}
              >
                <CommonLabel label="Şifre" />
                <CommonTextField
                  _placeHolder="Şifreni gir"
                  _type="password"
                  formName="password"
                />
              </div>
              <div
                style={{
                  textAlign: "end",
                  marginTop: "2%",
                  marginBottom: "4%",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#2574FF",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/reset-password")}
                >
                  Şifremi Unuttum
                </Typography>
              </div>

              <CommonButton text="Giriş Yap" />
            </form>
            <div style={{ display: "flex", marginTop: "4%" }}>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#9CA4AB",
                }}
              >
                Hesabın yok mu?
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#2574FF",
                  marginLeft: "2px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/register")}
              >
                Ücretsiz Üye Ol
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
