import { CreateProgressLog, GetPrivateUrlofArticleVideoResponse, ProgressStatusItem, UserService } from "../client";

export const shuffleAndGetContent = (items: any[], return_data_limit: number): any[] => {
  try {
    return items.slice().sort(() => Math.random() - 0.5).slice(0, return_data_limit);
  } catch (error) {
    return items
  }
};

export const getNameTextLogo = (name?: string) => {
  try {
    if (name == null) {
      return ""
    }

    if (name == undefined) {
      return "";
    }

    const names = name.split(" ");
    if (names.length == 1) {
      return names[0].charAt(0);
    } else {
      let res = "";

      res += names[0].charAt(0);
      res += names[names.length - 1].charAt(0);
      return res;
    }
  } catch (error) {
    return ""
  }
}

export const makeFirstLettersUpperCase = (text: string) => {
  try {
    return text
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } catch (error) {
    return ""
  }
}

export const doesUserHasAccessToContent = (isUserPremium:boolean, isContentFree:boolean) => {
  try {
    if(isUserPremium){
      return true
    }else{
      if(isContentFree){
        return true
      }else{
        return false
      }
    }
    
  } catch (error) {
    return false
  }
}

export function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
}

export const googleSignInFetchUserFromToken = async(access_token:string) => {
  try {
    let response = await fetch('https://www.googleapis.com/userinfo/v2/me', {
			headers: { Authorization: `Bearer ${access_token}` },
		})
		const user = await response.json()
    return user
  } catch (error:any) {
    return {}
  }
}

export const getVideoUrl = async(articleId:string,recaptchaToken:string, secondRequestCookie?:string ) => {
  try {
    const response = await UserService.getPrivateUrlOfArticleVideoUserGetVideoOfArticleArticleIdGet(articleId,recaptchaToken)
    return response.url
  } catch (error) {
    return ""
  }
}

export const isUserInTurkey = async() => {
  try {
    const response = await fetch('https://ipapi.co/json/')
    const data = await response.json()
    if(data?.country === "TR"){
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export const upsertProgressStatusLocally = (newLog:CreateProgressLog, allStatus:ProgressStatusItem[]) => {
  try {
    // const logExists = allStatus.find((e:ProgressStatusItem) => e.item_id == newLog.item_id && e.progress_status_type == newLog.item_type)
  } catch (error) {}
}