import { Button, Typography } from "@mui/material"
import { FC } from "react"

interface Props {
    text:string;
    fontSize?:string;
    onClick?: () => void;
    disabled?:boolean;
    customCSS?: {};
}

const CommonLightButton:FC<Props> = ({text="", onClick,fontSize="18px",disabled=false,customCSS}) => {

    const buttonStyle = {
        backgroundColor: disabled ? "#BFC6CC" :"none",
        padding:"14px 30px",
        borderRadius:"12px",
        border: disabled ? "":"1px solid #2574FF",
        ...customCSS
    }

  return (
    <Button style={buttonStyle} onClick={onClick} type="submit" fullWidth disabled={disabled}>
        <Typography style={{
            fontSize:fontSize == null || undefined ? "14px" : fontSize,
            fontWeight:"500",
            lineHeight:"22px",
            fontFamily:"Montserrat",
            color:"#2574FF",
            textTransform:"none",
        }}>
            {text}
        </Typography>
    </Button>
  )
}

export default CommonLightButton